<template>
  <b-card title="SMS Gönderim Detayı">
    <b-table
      striped
      hover
      responsive
      fixed
      :fields="fields"
      :items="detailData"
    >
      <template #cell(customer_name)="data">
        <div>{{ data.item.customer_name }}</div>
        <small
          v-if="data.item.company_name"
          class="text-info"
        >
          {{ data.item.company_name }}
        </small>
      </template>
      <template #cell(status)="data">
        <b-badge :variant="data.item.status? 'success' : 'danger'">
          Başarılı
        </b-badge>
        <div class="text-warning">
          {{ data.item.username }}
        </div>
        <div class="text-info">
          {{ moment(data.item.created).format('LLL') }}
        </div>
      </template>
      <template #cell(reference_used)="data">
        <div
          v-if="data.item.reference_used"
          class="text-success"
        >
          {{ moment(data.item.reference_used).format('LLL') }}
        </div>
      </template>
      <template #cell(customer_used)="data">
        <div
          v-if="data.item.customer_used"
          class="text-warning"
        >
          {{ moment(data.item.customer_used).format('LLL') }}
        </div>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import { BCard, BTable, BBadge } from 'bootstrap-vue'

const tableCustomClass = 'font-small-2 align-middle'
export default {
  name: 'Details',
  components: {
    BCard, BTable, BBadge,
  },
  props: {
    detailData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'id_com_service_tracing', label: 'SERVİS NO', sortable: true, thClass: tableCustomClass, tdClass: tableCustomClass,
        },
        {
          key: 'customer_name', label: 'MÜŞTERİ ADI', sortable: true, thClass: tableCustomClass, tdClass: tableCustomClass,
        },
        {
          key: 'phone', label: 'TELEFON', sortable: true, thClass: tableCustomClass, tdClass: tableCustomClass,
        },
        {
          key: 'reference_used', label: 'REFERANS KULLANIM', sortable: true, thClass: tableCustomClass, tdClass: tableCustomClass,
        },
        {
          key: 'customer_used', label: 'MÜŞTERİ HEDİYE KULLANIM', sortable: true, thClass: tableCustomClass, tdClass: tableCustomClass,
        },
        {
          key: 'sms_id', label: 'SMS ID', sortable: true, thClass: tableCustomClass, tdClass: tableCustomClass,
        },
        {
          key: 'status', label: 'GÖNDERİM', sortable: true, thClass: tableCustomClass, tdClass: `${tableCustomClass} text-center width-200`,
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
