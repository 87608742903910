<template>
  <b-card>
    <b-row>
      <b-col>
        <b-form-datepicker
          v-model="filterData.sdate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-col>
      <b-col>
        <b-form-datepicker
          v-model="filterData.edate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-col>
      <b-col
        cols="auto"
        class="d-flex align-items-center"
      >
        <b-form-checkbox
          v-model="filterData.detail"
          name="check-button"
          switch
          inline
        >
          Sms Detayını Göster
        </b-form-checkbox>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          :disabled="!filterData.sdate || !filterData.edate"
          @click="getFilterData"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard, BRow, BCol, BFormDatepicker, BButton, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  name: 'FilterData',
  components: {
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
  },
  props: {
    getFilterData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filterData() {
      return this.$store.getters['bcsSmsReport/getFilterData']
    },
  },
}
</script>
