<template>
  <b-card title="Rapor Özeti">
    <b-row>
      <b-col>
        <counter-item
          title="Gönderilen Kod"
          :value="counts.sms"
          icon="MessageCircleIcon"
          color="light-info"
        />
      </b-col>
      <b-col>
        <counter-item
          title="Referans Kullanımı"
          :value="counts.reference_used"
          icon="UsersIcon"
          color="light-success"
        />
      </b-col>
      <b-col>
        <counter-item
          title="Müşteri Hediye Kullanımı"
          :value="counts.customer_used"
          icon="GiftIcon"
          color="light-warning"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import CounterItem from '@/views/Reports/Service/BcsSms/CounterItem.vue'

export default {
  name: 'Summary',
  components: {
    BCard,
    BRow,
    BCol,
    CounterItem,
  },
  props: {
    counts: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
